import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_territory_picker = _resolveComponent("territory-picker")
  const _component_cyan_page_wrapper = _resolveComponent("cyan-page-wrapper")
  const _component_ion_page = _resolveComponent("ion-page")

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_cyan_page_wrapper, {
        title: _ctx.title,
        backRoute: _ctx.backRoute,
        nav1: "PUNTO INFO",
        ambito: _ctx.ambito
      }, {
        default: _withCtx(() => [
          _createVNode(_component_territory_picker, {
            nextRoute: "/puntoPickCentro/",
            seedTP: _ctx.seed
          }, null, 8, ["seedTP"])
        ]),
        _: 1
      }, 8, ["title", "backRoute", "ambito"])
    ]),
    _: 1
  }))
}